import React from "react";

import { ReactMockForm } from "mocks/edit-view/MockForm";
import { MockAppView } from "mocks/MockAppView";

const mockInstructions = `Instructions preface forms with cues to meet 
accessibility requirements.`;

const mockMessages = [
  // {
  //   variant: "danger",
  //   content:
  //     "When submitting a form fails, or some other non-field specific " +
  //     "error occurs, explain here.",
  // },
  // {
  //   variant: "warning",
  //   content: "It's possible for multiple messages to be present at a time.",
  // },
];

// TODO: Refactor to use ApplicationContext
const SingleObject = () => {
  return (
    <div>disabled</div>
    // <MockAppView title={"Create/Edit Form View"} instructions={mockInstructions} messages={mockMessages}>
    //   <ReactMockForm />
    // </MockAppView>
  );
};

export default SingleObject;
